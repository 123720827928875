<template>

    <div class="embed-responsive" :class="[css]" v-if="valid">
        <iframe loading="lazy" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
            class="embed-responsive-item" allowfullscreen :src="url" style="border: 0; position: absolute !important; top: 0 !important; left: 0 !important; width: 100% !important; height: 100% !important;"></iframe>
    </div>

</template>



<script>
    export default {
        props: {

            css: {
                type: String,
                default: 'embed-responsive-16by9'
            },

            src: { type: String },

            params: {
                type: Object
            },
        },

        data() {
            return {

                valid: false,
                url: '',

                videos: [
                    {
                        //reg: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/i,
                        //reg: /^.*(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
                        reg: 'yt',
                        url: 'https://www.youtube.com/embed/$5',
                        params: {
                            'picture-in-picture': 1,
                            accelerometer: 1,
                            gyroscope: 1,
                            

                        }


                    },
                    {
                        reg: 'vimeo',
                        url: 'https://player.vimeo.com/video/$1',
                        params: {
                            title: 0,
                            byline: 0,
                            portrait: 0
                        },


                    },

                    {
                        reg: 'dailymotion',
                        url: 'https://www.dailymotion.com/embed/video/$1',
                        params: {
                            autoplay: 0
                        }
                    },

                    {
                        reg: 'coub',
                        url: 'https://coub.com/embed/$1',
                        params: {
                            autoplay: 0
                        }
                    },



                    // {
                    //     reg: /^.*twitch.tv\/videos\/(\d+)($|\/|\b)/i,
                    //     url: 'https://player.twitch.tv/?autoplay=false&video=v$1',
                    //     params: {
                    //         autoplay: false
                    //     },
                    //     callback: function () {
                    //         return 'https://player.twitch.tv/?video=v$1';
                    //     }

                    // }
                ],

            }
        },


        watch: {
            src(val) {
                if(val) {
                    return this.parse()
                }
                this.parse()
            },
        },

        methods: {

            parse() {
                let yt = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w]+\?v=|embed\/|v\/)?)([\w]+)(\S+)?$/i
                let vimeo = /^.*vimeo.com\/(\d+)($|\/|\b)/i
                let dailymotion = /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#]*).*/i
                let coub = /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#]*).*/i
                
                this.url = ''

                if (this.src) {
                    for (let i = 0; i < this.videos.length; i++) {
                        const v = this.videos[i];
                        var m = null
                        if(v.reg == 'yt'){
                            m = yt.exec(this.src);
                        }else if(v.reg == 'vimeo'){
                            m = vimeo.exec(this.src);
                        }else if(v.reg == 'dailymotion'){
                            m = dailymotion.exec(this.src);
                        }else if(v.reg == 'coub'){
                            m = coub.exec(this.src);
                        }


                        if (m) {

                            var params = Object.assign({}, v.params, this.params);
                            var query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                            var and = v.url.indexOf('?') >= 0 ? '&' : '?';
                            
                            let ytTime = this.src.split('?t=')
                            
                            if(v.reg == 'yt'){
                                if(ytTime.length == 1) {
                                    this.url = this.src.replace(yt, v.url) + and + query;
                                }else{
                                    this.url = this.src.replace(yt, v.url) + and + 'start=' + ytTime[1] +'&'+query;
                                }
                            }else if(v.reg == 'vimeo'){
                                this.url = this.src.replace(vimeo, v.url) + and + query;
                            }else if(v.reg == 'dailymotion'){
                                this.url = this.src.replace(dailymotion, v.url) + and + query;
                            }else if(v.reg == 'coub'){
                                this.url = this.src.replace(coub, v.url) + and + query;
                            }
                            // this.url = this.src.replace(v.reg, v.url) + and + query;
                            this.valid = true;

                            // console.log(this.url);

                            return;
                        }

                    }

                }

                this.valid = false;
            },

        },

        mounted() {

            this.parse();

        }

    }

</script>